import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchChannels(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/youtube/channel", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchChannel(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/youtube/channel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addChannel(ctx, channelData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/youtube/channel", channelData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteChannel(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/youtube/channel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        syncChannels() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/youtube/syncChannels")
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
