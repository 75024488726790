<template>
    <div>
        <user-list-add-new
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />

        <!-- Filters -->
        <users-list-filters :status-filter.sync="statusFilter" :status-options="statusOptions" />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="8">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                            <b-button
                                variant="primary"
                                class="mr-1 text-nowrap"
                                :disabled="buttonDisabled"
                                @click="syncChannels"
                            >
                                <span>Synchronize</span>
                            </b-button>
                            <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                                <span class="text-nowrap">Add Channel</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: User -->
                <template #cell(name)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="48"
                                :src="data.item.picture"
                                :text="avatarText(data.item.fullName)"
                                variant="light-primary"
                                :to="{ name: 'marketing-channel-view', params: { id: data.item.id } }"
                            />
                        </template>
                        <b-link
                            :to="{ name: 'marketing-channel-view', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ data.item.name }}
                        </b-link>
                        <small class="text-muted">{{ data.item.channelId }}</small>
                    </b-media>
                </template>

                <!-- Column: Status -->
                <template #cell(isClosed)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(data.item.isClosed)}`"
                        class="text-capitalize"
                    >
                        <span v-if="!data.item.isClosed"> Active </span>
                        <span v-else> Inactive </span>
                    </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :to="{ name: 'marketing-channel-view', params: { id: data.item.id } }">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">Details</span>
                        </b-dropdown-item>

                        <b-dropdown-item v-b-modal="`modal-${data.item.id}`">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                            <b-modal
                                :id="`modal-${data.item.id}`"
                                title="Delete Youtube Channel"
                                ok-variant="danger"
                                ok-title="Delete"
                                @ok="deleteChannel(data.item.id)"
                            >
                                <p class="my-4">Are you sure to delete youtube channel?</p>
                            </b-modal>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-row v-if="totalUsers === 0" class="mx-2 d-flex justify-content-center"
                ><span class="font-weight-bold">No Records Found</span></b-row
            >
            <div v-if="tableLoading" class="text-center my-1">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import UsersListFilters from "./ChannelListFilters.vue";
import useUsersList from "./useChannelList";
import userStoreModule from "../channelStoreModule";
import UserListAddNew from "./ChannelListAddNew.vue";

export default {
    components: {
        UsersListFilters,
        UserListAddNew,
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });

        const isAddNewUserSidebarActive = ref(false);

        const statusOptions = [
            {
                label: "Active",
                value: "false",
            },
            {
                label: "Inactive",
                value: "true",
            },
        ];

        const buttonDisabled = ref(false);

        const {
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useUsersList();

        const syncChannels = () => {
            buttonDisabled.value = true;
            store.dispatch("app-user/syncChannels").then(() => {
                refetchData();
                buttonDisabled.value = false;
            });
        };

        const deleteChannel = channelId => {
            store.dispatch("app-user/deleteChannel", channelId).then(() => {
                refetchData();
            });
        };

        return {
            // Sidebar
            isAddNewUserSidebarActive,
            tableLoading,
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            statusOptions,
            syncChannels,
            deleteChannel,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,

            buttonDisabled,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
